// import external dependencies
import 'jquery';
import Stickyfill from 'stickyfilljs';
import 'alpinejs';

// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
// const bodyScrollLock = require('body-scroll-lock');
// const disableBodyScroll = bodyScrollLock.disableBodyScroll;
// const enableBodyScroll = bodyScrollLock.enableBodyScroll;
// import Es6Promise from 'es6-promise';
// Es6Promise.polyfill();
// Import everything from autoload
// import "./autoload/**/*"



// import local dependencies
// import Router from './util/Router';
// import common from './routes/common';
// import home from './routes/home';
// import aboutUs from './routes/about';

// import stickyHeader from './util/stickyHeader';
import navToggle from './util/navToggle';

/** Populate Router instance with DOM routes */
// const routes = new Router({
//   // All pages
//   common,
//   // Home page
//   home,
//   // About Us page, note the change from about-us to aboutUs.
//   aboutUs,
// });

var positionStickySupport = function() {
 var el = document.createElement('a'),
     mStyle = el.style;
 mStyle.cssText = "position:sticky;position:-webkit-sticky;position:-ms-sticky;";
 return mStyle.position.indexOf('sticky')!==-1;
}();

if(positionStickySupport) {
	document.getElementById('nav-drawer').classList.add('sticky');
}

navToggle.init("nav-open", "nav-close", "nav-drawer", "backdrop");
// stickyHeader.init("nav-drawer");
var elements = document.querySelectorAll('.sticky');
Stickyfill.add(elements);

// Load Events
// jQuery(document).ready(() => routes.loadEvents());

// jQuery('#my-ID .my-color-picker-class').each(function(){
//         jQuery(this).wpColorPicker({
//             // you can declare a default color here,
//             // or in the data-default-color attribute on the input
//             //defaultColor: false,

//             // a callback to fire whenever the color changes to a valid color
//             change: function(event, ui){},
//             // a callback to fire when the input is emptied or an invalid color
//             clear: function() {},
//             // hide the color picker controls on load
//             hide: true,
//             // set  total width
//             width : 200,
//             // show a group of common colors beneath the square
//             // or, supply an array of colors to customize further
//             palettes: ['#444444','#ff2255','#559999','#99CCFF','#00c1e8','#F9DE0E','#111111','#EEEEDD']
//         });
// });
//
