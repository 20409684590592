import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'

export default {
	init(openElementId, closeElementId, drawerElementId, backdropElementId) {
		var navOpen = document.getElementById(openElementId);
		var navClose = document.getElementById(closeElementId);
    var navDrawer = document.getElementById(drawerElementId);
    var backdrop = document.getElementById(backdropElementId);

		navOpen.addEventListener('click', function() {
      navDrawer.classList.toggle('navigation-visible');
      backdrop.classList.toggle('backdrop');
      disableBodyScroll(backdrop);
    });

    backdrop.addEventListener('click', function() {
      navDrawer.classList.toggle('navigation-visible');
      backdrop.classList.toggle('backdrop');
      enableBodyScroll(backdrop);
    })

		navClose.addEventListener('click', function() {
      backdrop.classList.toggle('backdrop');
			navDrawer.classList.toggle('navigation-visible');
      enableBodyScroll(backdrop);
		});

	}
}
